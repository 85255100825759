<template>
<div :appendToBody="true" class="dateInputWrapper" style='font-size:9pt;' >

<DatePicker  :multiple='!getRangeVal()' :range='getRangeVal()' :appendToBody="true"  
        :disabled-date="disabledDates" style='width: 80%;'
        @change="selected" @confirm="closeMe()" 
        :confirm="true" :clearable="true" :value-type="'YYYY-MM-DD'"
        :disabled='disabled' v-model="internalValue"/>
        
        <span @click='change()' v-tooltip="'change between single days or range'">
        <mdicon v-if="getRangeVal()" name="calendar-range-outline"/>
        <mdicon v-else name="calendar-month-outline"/>
        </span>
        {{ closed }}
</div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
var momentTZ = require('moment-timezone');
export default {
  name: "DatePickM",
  components : {
	  DatePicker
  },
   props: {
    value: {
      type: [Date, String]
      
    },
    from: String,
    to: String,
    disabled: Boolean
  },
  data () {
    return {
      fromDate: 0,
      toDate: 0,
      range: true,
      closed: false
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.parse( this.value);
      },
      set(v) {
    	   this.selected(v)
        
      }
    }
   },
    methods: {
    	
		customFormatter(date) {
		return momentTZ(date).tz(sessionStorage.timezone).format(sessionStorage.dateFormat);
		},
		getRangeVal()
		{
			return this.range;
		},
		closeMe()
		{
			this.closed = true;
		},
		change()
		{
			this.range = !this.range;
			
			this.$forceUpdate();
		},
		selected(d)
		{
			let x;
			if ( this.range )
			{
				x = ['R', ...d];
			}
			else
			{
				x = ['D', ...d];
			}
			this.closed = true;
			this.$emit('change', x)
		},
		
		disabledDates(d, arr)
		{
			this.getRange()
			if ( d.getTime() < this.fromDate ||  d.getTime() > this.toDate )
			{
				return true;	
			}
			return false;
		},
		getRange()
		{
			this.fromDate = new Date( this.from).getTime()-84600*1000;
	    	this.toDate = new Date( this.to).getTime();
		},
		parse(value)
		{
			if ( !value || value.length < 1 )
			{
				return value;
			}

			if ( value[0] == 'R' )
    		{
    			this.range = true;
    			let x = [...value];
    			x.shift();
    			return x;
    		}
    		else
    		{
    			this.range = !true;
    			let x = [...value];
    			x.shift();
    			return x;
    		}
		}
		
     },
    created() {
    	
    },

    watch:
    {
    	value: function() { 
    		
    	},
    	from: function()  { this.getRange() },
    	to: function()  { this.getRange() }
    } 
 }
</script>
<style scoped lang="scss">
.dateInputWrapper { 
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    width: 100%;
    font-size: 11pt;
	white-space: normal;
	overflow: visible !important;
}
.comp_datepicker { font-size: 10pt; border: none !important; width: 100pt !important;}
.comp_small { font-size: 10pt; padding: 1; height: 10px;}
.comp_datepicker:disabled { font-size: 11pt; border: none; width: 100%; color: #888; background-color: transparent;}
input { border: none !important; }

</style>