<template>
<div class='inputWrapper' :style='getMainStyle()'>
    <span class='span25'>
    <span v-for="day in weekDayIndexes()" :key="day">
        <span v-if="weekday[day]">{{weekdayLabel[day]}}</span>
        <span v-else>--</span>
    </span>
    
    </span>
</div>
</template>
<script>
export default {
  props: {
    value: Number,
    width: {type: String, defaultValue: "100%"},
    fontsize: {type: String, defaultValue: "12pt"},
    time: Number,
    disabled: Boolean
  },
  data(){
    return {
        val: Number,
        weekday: [],
        weekdayLabel: []
    }
  },
  components : {
    
  },
  methods: {
      getMainStyle() {
      	return "font-size: "+this.fontsize+";";
      },
      weekDayIndexes() {
            var intArr = [];
            //console.log(sessionStorage.weekStart);
            for ( var i=0; i < this.weekdayLabel.length; i++ )
            {
                intArr.push((i + parseInt(sessionStorage.weekStart)) % 7);
                //console.log(i+"/" + sessionStorage.weekStart+ "--> " +((i + sessionStorage.weekStart) % 7));
            }
            //console.log("weekDayIndexes>" +JSON.stringify(intArr));
            return intArr;
        },
      enterPressed() { this.$emit("enter"); },
      calc()
      {
          let p2 = 1;
          this.val = 0;
          var x = "";
          for ( var ii = 0; ii < 7; ii++ )
          {
            this.val += this.weekday[ii] ? p2 : 0;
            x = x + "/"+ this.weekday[ii];
            p2 = p2 * 2;
          }
          //this.val = v; // (this.sun?1:0)+(this.mon?2:0)+(this.tue?4:0)+(this.wed?8:0)+(this.thu?16:0)+(this.fri?32:0)+(this.sat?64:0);
          //console.log("calc> " + this.val );
          //console.log("calc>" +this.val+ " <-- " + x);
          this.$emit("input", this.val);
      },
      reverted() {
          this.setValues();
          this.calc();
      },
      setValues()
      {
          if ( ! this.val)
          {
              this.val = 127;
          }
          //console.log("set>" +this.val + " " + this.value);
          this.weekdayLabel = [];
          let wdArr = JSON.parse(sessionStorage.weekdays);
          for ( var i=1; i < 8; i++ )
          {
             let x = wdArr[i];
             if ( x.length > 2 )
             {
                 x = x.substring(0,2);
             }
             this.weekdayLabel.push( x);    
          }
          this.weekday = new Array(7);
          let p2 = 1;
          var x = "";
          for ( var ii = 0; ii < 7; ii++ )
          {
            this.weekday[ii] = (this.val & p2) == p2;
            x = x + "/"+ this.weekday[ii];
            p2 = p2 * 2;
          }
          //console.log("set>" +this.val+ " --> " + x);
          this.$emit("input", this.val)
      }
  },
  watch: {
      value: function() {
          this.val = parseInt(this.value);

          this.setValues();
      },
      val: function() {
          this.val = parseInt(this.value);

          this.setValues();
      },
    time: function() {
  		this.val = parseInt(this.value);

        this.setValues();
      }
  },
  created() { 
      this.val = parseInt(this.value);
      this.setValues();
  },
  computed: {
    internalValue: {
      get() {
        return this.val;
      },
      set(v) {
        this.$emit("input", this.val)
      }
    }
    }
 }
</script>
<style lang="scss" scoped>
input { border: none;}
input:disabled { font-weight: bold;color: #888; background-color: #fff;}
input:focus { outline: 1pt dotted grey;}
.cb {white-space: nowrap; }
.span25 { white-space: nowrap;  width: 85%}
.dist5 { margin-left: 4pt; }
.span75 {  width: 14%; text-align: right; float: right; }
.inputWrapper { 
    display: inline-flex;
}

.inputButton { 
    border: 1pt solid grey;
}

</style>